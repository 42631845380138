<script>
import errorsMixin from '@/mixins/errors.mixin'

export default {
  name: 'CourseCreateProvider',

  mixins: [errorsMixin],

  props: {
    formErrors: Object
  },

  render() {
    return this.$scopedSlots.default({
      formErrors: this.formErrors,
      checkValidatorBeforeSend: this.checkValidatorBeforeSend,
      formData: this.formData,
      send: this.send
    })
  },

  inject: ['svc'],

  data:() => ({
    processing: false,
    formData: {
      city: '',
      date: '',
      notes: '',
      name: '',
      isVisibleOnLanding: true,
      reservableLicenseTypes: [],
    },
    fakeExamData: {
      date: '01-01-3000',
      dateFrom: '01-01-3000',
      dateTo: '01-01-3000',
      exam_date_future_days: null,
      rangeMode: true
    }
  }),

  methods: {
    toggle() {
      this.updateShowing(!this.showing)
    },

    checkValidatorBeforeSend(payload) {
      this.send(payload)
    },

    async send({data, examData}) {
      try {
        this.processing = true
        let res = await this.svc.createCourse(data)
        this.$emit('create', res)
        this.$notify({text: 'Course created', type: 'success'})
        await this.createFakeExam(res.id, {...examData, ...this.fakeExamData})
        this.$emit('end')
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      }
      finally {
        this.processing = false
      }
    },

    async createFakeExam(courseID, data) {
      try {
        let res = await this.svc.createConfig(courseID, data)
        this.$emit('create:exam', res)
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
        return Promise.reject(error)
      }
    }
  }
}
</script>
